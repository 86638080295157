import React, { useCallback } from 'react'
import { mintStore } from 'stores'
import Footer from '../Footer/Footer'
import { Icon } from 'components'
import { MINT_WIDGET_VIEWS } from 'mainConstants'
import { MintWidgetViewProps } from 'components/MintWidget/MintWidgetTypes'
import styles from './Done.module.scss'

const ETHERSCAN_LINK = 'https://goerli.etherscan.io/tx/'

function Done({ goTo }: MintWidgetViewProps) {
  const restart = useCallback(() => {
    mintStore.reset()
    goTo(MINT_WIDGET_VIEWS.UPLOAD)
  }, [])

  return (
    <div className={styles.root}>
      <div>
        <Icon className={styles.ok} type="ok" />
        <div className={styles.title}>Your NFT is processing.</div>
        <div className={styles.desc}>Check your wallet soon.</div>
      </div>
      <Footer label="Mint Another" onClick={restart} buttonType="grey" />
    </div>
  )
}

export default Done
