import React from 'react'
import { observer } from 'mobx-react'
import { mintStore } from 'stores'
import { Icon } from 'components'
import styles from './AuthorLink.module.scss'

function AuthorLink() {
  const { authorName, authorLink, authorAvatar } = mintStore

  return (
    <a className={styles.root} href={authorLink} target="_blank" rel="noreferrer">
      <div className={styles.avatar} style={{ backgroundImage: `url(${authorAvatar})` }} />
      <div className={styles.info}>
        Artwork by:
        <div className={styles.author}>
          {authorName}
          <Icon type="link" className={styles.link} />
        </div>
      </div>
    </a>
  )
}

export default observer(AuthorLink)
