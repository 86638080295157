import React from 'react'
import { ReactComponent as SpinnerSVG } from 'images/spinner.svg'
import styles from './Spinner.module.scss'

interface SpinnerProps {
  show?: boolean
}

export default function Spinner({ show }: SpinnerProps) {
  if (!show) {
    return null
  }
  return (
    <div className={styles.root}>
      <SpinnerSVG className={styles.spinner} />
    </div>
  )
}
