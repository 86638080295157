const space = process.env.REACT_APP_CONTENTFUL_SPACE_ID
const environment = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT
const accessToken = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN
const modelId = process.env.REACT_APP_CONTENTFUL_MODEL_ID

export async function fetchGraphQL(query: string): Promise<any> {
  try {
    const res = await fetch(`https://graphql.contentful.com/content/v1/spaces/${space}/environments/${environment}`, {
      body: JSON.stringify({ query }),
      headers: {
        authorization: `Bearer ${accessToken}`,
        'content-type': 'application/json',
      },
      method: 'POST',
    })
    const { data } = await res.json()

    return data
  } catch (error) {
    console.error(error)

    return null
  }
}

export async function getContentfulData() {
  try {
    const { launchpad } = await fetchGraphQL(`
    query {
      launchpad(id: "${modelId}") {
        authorName,
        authorLink,
        authorAvatar {
          url
        },
        background {
          url
        }
      }
    }`)
    return launchpad
  } catch (error) {
    console.error(error)
    return null
  }
}
