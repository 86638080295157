import { makeAutoObservable } from 'mobx'
import { EditForm, Image } from '../services/mint/mintTypes'
import { MIN_TEXT_HEIGHT } from 'mainConstants'
import api from '../services'

const initialEditForm: EditForm = {
  name: '',
  description: '',
  descriptionHeight: `${MIN_TEXT_HEIGHT}px`,
  properties: [],
}

export class MintStore {
  service: typeof api.mint
  authorName: string = ''
  authorLink: string = ''
  authorAvatar: string = ''
  background: string = ''
  editForm: EditForm = initialEditForm
  image: Image = { name: '', src: '', size: '', file: null }
  loading = false
  showInstallWalletModal = false
  showErrorModal = false
  error: null | string = null
  transactionHash: null | string = null

  constructor(MintService: typeof api.mint) {
    this.service = MintService
    makeAutoObservable(this)
  }

  loadContentfulData = async () => {
    this.loading = true
    try {
      const { authorName, authorLink, authorAvatar, background } = await api.contentful.getContentfulData()
      this.authorName = authorName
      this.authorLink = authorLink
      this.authorAvatar = authorAvatar.url
      this.background = background.url
    } catch (err) {
      console.log(err)
    }
    this.loading = false
  }

  setImage = (image: Image) => {
    this.image = image
    this.editForm.name = image.name.substring(0, image.name.lastIndexOf('.'))
  }

  reset = () => {
    this.image = { name: '', src: '', size: '', file: null }
    this.editForm = initialEditForm
    this.transactionHash = null
  }

  setShowInstallWalletModal = (show: boolean) => {
    this.showInstallWalletModal = show
  }

  setError = (error: null | string) => {
    this.showErrorModal = !!error
    this.error = error
  }

  setEditForm = (key: string, value: any) => {
    this.editForm[key] = value
  }

  setLoading = (value: boolean) => {
    this.loading = value
  }

  setTransactionHash = (value: string | null) => {
    this.transactionHash = value
  }
}

export const mintStore = new MintStore(api.mint)
