import React, { useState } from 'react'
import cx from 'classnames'
import { Modal } from 'alteon-component-library'
import { Icon } from 'components'
import styles from './VideoModal.module.scss'

const VIEWS = {
  PRIVACY_POLICY: 'privacy',
  TERMS_OF_SERVICE: 'terms',
  VIDEO: 'video',
}

interface VideoModalProps {
  onClose: () => void
}

function VideoModal({ onClose }: VideoModalProps) {
  const [activeView, setActiveView] = useState(VIEWS.VIDEO)
  let title, content

  switch (activeView) {
    case VIEWS.PRIVACY_POLICY:
      title = (
        <div className={styles.modalTitle}>
          <Icon type="back" className={styles.back} onClick={() => setActiveView(VIEWS.VIDEO)} />
          Alteon LaunchPad Privacy Policy
        </div>
      )
      content = (
        <div className={cx(styles.contentContainer, styles.policy)}>
          <div className={styles.title}>Effective Date: November 18, 2022</div>
          <p>
            This Alteon LaunchPad Platform Privacy Policy (<i>“Privacy Policy”</i>) describes how Alteon LaunchPad LLC,
            a Florida, USA based company (<i>“Alteon LaunchPad,”</i> <i>“we,”</i> <i>“us,”</i> or <i>“our”</i>)
            collects, uses, shares, and retains personal or tracking data that you provide to us, or that we collect,
            when you use the Alteon LaunchPad platform accessible at launchpad.alteon.io (the{' '}
            <i>“Alteon LaunchPad Platform”</i>), and the services, content and other materials made available through
            the Alteon LaunchPad Platform (the <i>“Alteon LaunchPad Services”</i>). As used in this Privacy Policy,
            references to the Alteon LaunchPad Platform shall be deemed to include all of the Alteon LaunchPad Services.
          </p>

          <p>
            This Privacy Policy explains what data we may collect about you, how we use and share that data, and your
            choices concerning our data practices.
          </p>

          <p>
            <i>
              PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY CLICKING “ACCEPT” AND/OR ACCESSING OR OTHERWISE USING THE
              Alteon LaunchPad Platform YOU AGREE TO THIS PRIVACY POLICY.
            </i>{' '}
            If at any time you do not accept the terms and conditions set forth in this Privacy Policy, you must
            immediately stop using the Alteon LaunchPad Platform.
          </p>

          <p>
            <i>1. Information We Collect from You through the Alteon LaunchPad Platform</i>
            <ol>
              <li>
                <i>Personal Data:</i> We do not collect name, email address, phone and mailing address.
              </li>
              <li>
                <i>Tracking Data:</i> Information We Receive Automatically From Your Use of the Alteon LaunchPad
                Platform. When you visit, use and interact with the Alteon LaunchPad Platform, we may receive certain
                information about your visit, use or interactions. For example, we may monitor the number of people that
                visit the Alteon LaunchPad Platform, peak hours of visits, which page(s) are visited on the Alteon
                LaunchPad Platform, the domains our visitors come from (e.g., google.com, yahoo.com, etc.), and which
                browsers people use to access and visit the Alteon LaunchPad Platform (e.g., Firefox, Microsoft Internet
                Explorer, etc.), broad geographical information, and website navigation patterns. In particular, the
                following information is created and automatically logged in our systems:
                <ul>
                  <li>
                    <i>Log data:</i> Information that your browser automatically sends whenever you visit the Alteon
                    LaunchPad Platform (“log data”). Log data includes your Internet Protocol (“IP”) address (so we
                    understand where you are connecting from when you visit the Alteon LaunchPad Platform), browser type
                    and settings, the date and time of your request, and how you interacted with the Alteon LaunchPad
                    Platform.
                  </li>
                  <li>
                    <i>Cookies:</i> We do not use cookies.
                  </li>
                  <li>
                    <i>Device information:</i> We collect the name of the device, operating system, and browser you are
                    using. Information collected may depend on the type of device you use and its settings.
                  </li>
                  <li>
                    <i>Usage Information:</i> We collect information about how you use the Alteon LaunchPad Platform,
                    such as the types of content that you view or engage with, the features you use, the actions you
                    take, and the time, frequency and duration of your activities.
                  </li>
                </ul>
              </li>
            </ol>
          </p>

          <p>
            This Privacy Policy explains what data (defined below) we collect, how we use and share that data, and your
            choices concerning our data practices.
          </p>

          <p>
            <i>2. Tracking Technologiess</i>
            <ol>
              <li>
                <i>Cookies.</i> We do not use cookies.
              </li>
              <li>
                <i>Analytics Providers.</i> We use Google Analytics, a web analytics service provided by Google, Inc.
                (“Google”). Google Analytics uses cookies to help us analyze how users use the Alteon LaunchPad Platform
                and enhance your experience when you use the Alteon LaunchPad Platform. For more information on how
                Google uses this data, go to www.google.com/policies/privacy/partners/.
              </li>
              <li>
                <i>Online Tracking and Do Not Track Signals.</i> We do not use cookies, pixels or other tracking
                technologies to collect information about your browsing activities over time and across different
                websites following your use of the Alteon LaunchPad Platform. The Alteon LaunchPad Platform currently
                does not respond to “Do Not Track” (“DNT”) signals and operates as described in this Privacy Policy
                whether or not a DNT signal is received. If we do respond to DNT signals in the future, we will update
                this Privacy Policy to describe how we do so.
              </li>
              <li>
                <i>Your Choices.</i> On most web browsers, you will find a “help” section on the toolbar. Please refer
                to this section for information on how to receive a notification when you are receiving a new cookie and
                how to turn cookies off. Most advertising networks offer you a way to opt out of targeted advertising.
                If you would like to find out more information, please visit the Network Advertising Initiative’s online
                resources at http://www.networkadvertising.organd follow the opt-out instructions there. If you access
                the Alteon LaunchPad Platform on your mobile device, you may not be able to control tracking
                technologies through the settings.
              </li>
            </ol>
          </p>

          <p>
            <i>3. Information Collected by Third Parties Through the Alteon LaunchPad Platform</i>
            <ol>
              <li>
                Information Collected by Third Parties through Third Party Links and Content. The Alteon LaunchPad
                Platform may include links to other websites and other content from third party businesses. These third
                party businesses may use cookies, web beacons or other similar technology to collect information about
                you. Alteon does not have access to or control over these third parties or the cookies, web beacons or
                other technology that these third parties may use. We are not responsible for the privacy practices of
                these third parties or the content on any third party website. You are encouraged to review the privacy
                policies of the different websites you visit.
              </li>
            </ol>
          </p>
          <p>
            <i>4. How We Use Your Information</i>
            <ul>
              <li>To respond to your inquiries, comments, feedback or questions;</li>
              <li>
                To analyze how you interact with the Alteon LaunchPad Platform and provide, maintain and improve the
                content and functionality of the Alteon LaunchPad Platform;
              </li>
              <li>
                To prevent fraud, criminal activity, or misuses of the Alteon LaunchPad Platform, block prohibited
                reseller traffic, and to ensure the security of our IT systems, architecture and networks; and
              </li>
              <li>
                To comply with legal obligations and legal process and to protect our rights, privacy, safety or
                property, and/or that of our affiliates, you or other third parties.
              </li>
            </ul>
          </p>
          <p>
            <i>5. Marketing.</i>
          </p>
          <p>
            We may tell you about services we believe will be of interest to you. For instance, if you elect to provide
            your email or telephone number, we may use that information to send you special offers relating to the
            Alteon LaunchPad Platform. You may opt out of receiving these emails by following the instructions contained
            in each promotional email we send you. You can also control the marketing messages you receive by updating
            your settings through your account. In addition, if at any time you do not wish to receive future marketing
            communications, please contact us at <a href="mailto:info@alteon.io">info@alteon.io</a>. If you unsubscribe
            from our marketing lists, you will no longer receive marketing communications but we will continue to
            contact you regarding the Alteon LaunchPad Platform and to respond to your requests.
          </p>
          <p>
            <i>6. How We Share Your Information</i>
          </p>
          <p>
            In certain circumstances we may share your data with third parties without further notice to you, unless
            required by the law, as set forth below:
            <ul>
              <li>
                <i>Vendors and Service Providers:</i> To assist us in meeting business operations needs and to perform
                certain services and functions, we may share your data with providers of hosting, development, payment
                processing, and analytics services. Pursuant to our instructions, these parties will access, process or
                store your data in the course of performing their duties to us. We take commercially reasonable steps to
                ensure our service providers adhere to the Security standards we apply to your data.
              </li>
              <li>
                <i>Business Transfers:</i> If we are involved in a merger, acquisition, financing due diligence,
                reorganization, bankruptcy, receivership, sale of all or a portion of our assets, or transition of
                service to another provider, your data and other information may be shared in the diligence process with
                counter parties and others assisting with the transaction and transferred to a successor or affiliate as
                part of that transaction along with other assets.
              </li>
              <li>
                <i>Legal Requirements:</i> If required to do so by law or in the good faith belief that such action is
                necessary to (i) comply with a legal obligation, including to meet national security or law enforcement
                requirements, (ii) protect and defend our rights or property, (iii) prevent fraud, (iv) act in urgent
                circumstances to protect the personal safety of users of the Alteon LaunchPad Platform, or the public,
                or (v) protect against legal liability.
              </li>
              <li>
                <i>With Your Consent:</i> We will share your data with your consent or at your direction. We will
                provide you with information about how your data will be shared at the time we obtain your consent.
              </li>
            </ul>
          </p>
          <p>
            <i>7. Data Retention</i>
          </p>
          <p>
            We keep data for as long as reasonably necessary for the purposes described in this Privacy Policy, while we
            have a business need to do so, or as required by law (e.g. for tax, legal, accounting or other purposes),
            whichever is the longer.
          </p>
          <p>
            <i>8. Children</i>
          </p>
          <p>
            The Alteon LaunchPad Platform is not directed to children who are under the age of 13. Alteon LaunchPad does
            not knowingly collect Personal Data from children under the age of 13. If you have reason to believe that a
            child under the age of 13 has provided Personal Data to Alteon through the Alteon LaunchPad Platform, please
            contact us at <a href="mailto:info@alteon.io">info@alteon.io</a> and we will try to delete that information
            from our databases.
          </p>
          <p>
            <i>9. Security</i>
          </p>
          <p>
            You use the Alteon LaunchPad Platform at your own risk. We comply with reasonable industry standards to
            protect Personal Data both online and offline from loss, misuse, and unauthorized access, disclosure,
            alteration or destruction. However, no Internet or e-mail transmission is ever fully secure or error free.
            In particular, e-mail sent to or from us may not be secure. Therefore, you should take special care in
            deciding what information you send to us through the Alteon LaunchPad Platform or by e-mail. In addition, we
            are not responsible for circumvention of any privacy settings or security measures contained on the Alteon
            LaunchPad Platform, or third party websites.
          </p>
          <p>
            <i>10. Transferring Personal Data to the U.S</i>
          </p>
          <p>
            Alteon LaunchPad has its headquarters in the United States. Alteon LaunchPad Platform is intended for Users
            located globally. The data we collect from you will be processed in the United States, and, subject to
            applicable law, may be transferred to and processed in other countries outside the United States. The
            standards and laws that apply to the protection of data in the United States or these countries may be
            different than the ones that are applicable to the country where you are located.
          </p>
          <p>
            <i>11. Your Choices</i>
          </p>
          <p>
            Whether or not you provide Personal Data to us is your decision, but if you choose not to provide
            information that is needed to use features of the Alteon LaunchPad Platform and/or Alteon LaunchPad
            Services, you may be unable to use those features. You can also contact us at{' '}
            <a href="mailto:info@alteon.io">info@alteon.io</a> to request access to your Personal Data or to ask us to
            update, correct, or delete your Personal Data.
          </p>
          <p>
            <i>12. Updates to this Privacy Policy</i>
          </p>
          <p>
            We may change, modify or amend this Privacy Policy from time to time. We will notify you of material changes
            to this Privacy Policy by posting the amended terms on the Alteon LaunchPad Platform at least thirty (30)
            days before the effective date of the changes. In addition, you will be required to affirmatively accept the
            new Privacy Policy the first time you visit the Alteon LaunchPad Platform and log in to your member account
            after the new Privacy Policy takes effect. If you do not agree with the proposed changes, you should
            discontinue your use of the Alteon LaunchPad Platform prior to the time the new Privacy Policy takes effect.
            If you continue using the Alteon LaunchPad Platform after the new Privacy Policy takes effect, you will be
            bound by the modified Privacy Policy.
          </p>
          <p>
            <i>13. Contact Us</i>
          </p>
          <p>
            If you have any questions about our Privacy Policy or the information practices relating to the Alteon
            LaunchPad Platform, please feel free to contact us at <a href="mailto:info@alteon.io">info@alteon.io</a>.
          </p>
        </div>
      )
      break
    case VIEWS.TERMS_OF_SERVICE:
      title = (
        <div className={styles.modalTitle}>
          <Icon type="back" className={styles.back} onClick={() => setActiveView(VIEWS.VIDEO)} />
          Alteon LaunchPad Terms and Conditions
        </div>
      )
      content = (
        <div className={cx(styles.contentContainer, styles.policy)}>
          <div className={styles.title}>Effective Date: November 18, 2022</div>
          <div className={styles.subTitle}>1. INTRODUCTION</div>
          <p>
            <i>1.1</i> These terms and conditions (<i>Terms</i>) are entered into between Alteon LaunchPad LLC, a
            Florida, USA based company (<i>Alteon LaunchPad</i>, <i>We</i>) and you (<i>User</i>, <i>You</i>), together
            the Parties and each a Party. These Terms apply to the use of Alteon LaunchPad (<i>Service</i>) to publish a
            non-fungible token (<i>NFT</i>).
          </p>

          <p>
            PLEASE READ THESE TERMS CAREFULLY AS THEY CONTAIN IMPORTANT INFORMATION AND AFFECT YOUR LEGAL RIGHTS. BY
            CLICKING TO ACCEPT AND/OR USING THE SERVICE, YOU AGREE TO BE BOUND BY THESE TERMS AND ALL OF THE TERMS
            INCORPORATED HEREIN BY REFERENCE. IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY NOT ACCESS OR USE THE SERVICE.
          </p>

          <p>
            <i>1.2</i> Alteon LaunchPad provides a web service for Users to publish non-fungible tokens (<i>NFTs</i>)
            linked with certain digital media and art (<i>Content</i>) to the Ethereum blockchain using a smart
            contract.
          </p>

          <p>
            <i>1.3</i> We are not a wallet provider, exchange, broker, financial institution, or creditor. We provide a
            web service that helps Users create an NFT from digital media and publish it to a blockchain. We do not have
            custody or control over the NFTs or blockchain the User is interacting with. We do not execute nor
            effectuate purchases, transfers, payments, or sales of NFTs. To use the Service, the User must use a
            third-party wallet which allows User to engage in transactions on blockchains. Users determine and engage
            with third-party wallet providers at their own discretion and risk.
          </p>

          <p>
            <i>1.4</i> We are not a party to any agreement between any Users or between a User and a third party.
          </p>

          <p>
            <i>1.5</i> We reserve the right to change or modify these Terms at any time and in our sole discretion. If
            We make material changes to these Terms, we will use reasonable efforts to provide notice of such changes,
            such as by providing notice through the Service or updating the last date at the beginning of these Terms.
            By continuing to access or use the Service, User confirms their acceptance of the revised Terms and all of
            the terms incorporated therein by reference effective as of the date these Terms are updated. It is User’s
            sole responsibility to review the Terms from time to time to view such changes and to ensure that User
            understands the terms and conditions that apply when User accesses or uses the Service.
          </p>

          <p>
            <i>1.6</i> In these Terms, the User means (as applicable) the person or entity browsing or using the Service
            (i.e. Alteon LaunchPad).
          </p>

          <p>
            <i>1.7</i> By using your wallet in connection with the Service, you agree that you are using that wallet
            under the terms and conditions of the applicable provider of the wallet. Wallets are not operated by,
            maintained by, or affiliated with the Service, and we do not have custody or control over the contents of
            the User wallet and have no ability to retrieve or transfer its contents. We accept no responsibility for,
            or liability to you, in connection with your use of a wallet and make no representations or warranties
            regarding how the Service will operate with any specific wallet. If you discover an issue related to your
            wallet, please contact your wallet provider. We will not be liable for any failure or non-performance of
            such services, or for any damages or losses incurred in connection with the use of such services.
          </p>

          <div className={styles.subTitle}>2. ACCEPTANCE AND SERVICE SUMMARY</div>

          <p>
            <i>2.1</i> These Terms constitute a legal agreement between you and Alteon LaunchPad and govern your access
            to and use of the Service (i.e. Alteon LaunchPad), including any content, functionality, and services
            offered on or through the Service. By using the Service, Users will be deemed to have accepted these Terms.
          </p>

          <p>
            <i>2.2</i> Alteon LaunchPad provides the Service to Users to assist in creation and publication of an NFT.
          </p>

          <p>
            <i>2.3</i> You understand and agree that Alteon LaunchPad only enables you to create and publish an NFT
            through the Service. Alteon LaunchPad is not a party to any agreement entered into between you and other
            parties to transact on that NFT.
          </p>

          <div className={styles.subTitle}>3. COMMUNICATION</div>

          <p>
            <i>3.1</i> By using the Service, you agree that we may provide you with notices (including, but not limited
            to, those regarding changes to these Terms) or other communications, including marketing, related to your
            use of the Service electronically via email (if you provide an email address).
          </p>

          <div className={styles.subTitle}>4. INTELLECTUAL PROPERTY RIGHTS AND CONTENT</div>

          <p>
            <i>4.1</i> Subject to a User’s compliance with these Terms, Alteon LaunchPad grants Users a personal,
            non-exclusive, royalty-free, revocable, worldwide, non-transferable license to use the Service. All other
            uses are prohibited without Alteon LaunchPad’s prior written consent.
          </p>

          <p>
            <i>4.2</i> In connection with your use of the Service, you retain all rights to your Content except for
            rights expressly granted herein. In order to operate the Service, we must obtain from you certain license
            rights in your Content so that actions Alteon LaunchPad takes in operating the Service are not considered
            legal violations. By posting or submitting your Content to the Service, you represent and warrant that you
            have, or have obtained, all rights, licenses, consents, permissions, power and/or authority necessary to
            grant the rights granted herein for your Content. You agree that your Content will not contain material
            subject to copyright or other proprietary rights, unless you have the necessary permission or are otherwise
            legally entitled to post the material and to grant us the license described above.
          </p>

          <p>
            <i>4.3</i> Users must not, without the prior written consent of the owner of the Content:
            <ul>
              <li>copy or use, in whole or in part, any Content;</li>
              <li>
                reproduce, reverse engineer, retransmit, distribute, disseminate, sell, publish, broadcast or circulate
                any Content designated as paid, exclusive or non-shareable content to any third party;
              </li>
            </ul>
          </p>

          <p>
            <i>4.4</i> Users agree that they are solely responsible for all User Content that they make available on the
            Service.
          </p>

          <p>
            <i>4.5</i> Users represent and warrant that:
            <ul>
              <li>
                they are either the sole and exclusive owner of all User Content or that they have all rights, licenses,
                consents and releases that are necessary to grant to Alteon LaunchPad the rights in such User Content as
                contemplated by these Terms; and
              </li>
              <li>
                neither the User Content, nor the posting, uploading, publication, sending or receiving of the User
                Content or our use of the User Content on, through or by means of the Service will infringe,
                misappropriate or violate a third party's Intellectual Property Rights, or rights of publicity or
                privacy, or result in the violation of any applicable law or regulation.
              </li>
            </ul>
          </p>

          <p>
            <i>4.6</i> To the fullest extent permitted by applicable law, you agree to indemnify, defend and hold Us,
            the API Provider, API Provider’s suppliers and contractors and API Provider’s Personnel harmless and our
            past, present and future parent, subsidiaries and affiliates, and our and their respective employees,
            officers, directors, contractors, consultants, equity holders, suppliers, vendors, service providers, parent
            companies, subsidiaries, affiliates, agents, representatives, predecessors, successors and assigns
            (individually and collectively the <i>“Affiliates”</i>), from and against all actual or alleged claims,
            damages (actual and consequential), awards, judgments, losses, liabilities, obligations, penalties,
            interest, fees, expenses (including, without limitation, attorneys’ fees, court costs, costs of settlement
            and costs of pursuing indemnification and insurance), of every kind and nature whatsoever, whether known or
            unknown, foreseen or unforeseen, matured or unmatured, or suspected or unsuspected, in law or equity,
            whether in tort, contract or otherwise (collectively, <i>“Claims”</i>), including, but not limited to,
            damages to property or personal injury, that we may suffer, incur or otherwise become liable for, that are
            caused by, arise out of or are related to (a) your use or misuse of the Service, (b) your breach of these
            Terms, and (c) your breach or violation of the rights of a third party, including as a result of an
            Intellectual Property Breach, another user or third-party service provider. You agree to promptly notify Us
            of any such Claims and cooperate with Us in defending such Claims.
          </p>

          <p>
            <i>4.7</i> Unless otherwise indicated, Alteon LaunchPad owns or licenses all rights, title and interest
            (including Intellectual Property Rights) in the Service and all of Service Content.
          </p>

          <p>
            <i>4.8</i> The Service Content is not comprehensive and is for general information purposes only. It does
            not take into account any User’s specific needs, objectives or circumstances, and is not advice. While we
            use reasonable attempts to ensure the accuracy and completeness of the Service Content, to the extent
            permitted by law, we make no representation or warranty regarding the Service Content. The Service Content
            is subject to change without notice. We do not undertake to keep our Service up-to-date and we are not
            liable if any Service Content is inaccurate or out-of-date.
          </p>

          <p>
            <i>4.9</i> This clause 4 will survive the termination or expiry of these Terms.
          </p>

          <div className={styles.subTitle}>5. WARRANTIES</div>

          <p>
            <i>5.1</i> Users represent, warrant and agree that:
            <ul>
              <li>
                they will not use the Service, including the Service Content, in any way that competes with Alteon
                LaunchPad’s business;
              </li>
              <li>there are no legal restrictions preventing Users from entering into these Terms;</li>
              <li>
                all information and documentation that Users provide to the Service in connection with these Terms is
                true, correct and complete;
              </li>
              <li>they have all rights to their Content</li>
              <li>
                Alteon LaunchPad provides the Service and any software operating it (including any associated supplier
                software and Application Programming Interfaces accessed to operate the Service) on an ‘as is’ basis and
                that we make no representations or warranties regarding the software.
              </li>
              <li>
                they will be responsible for the use of the Service, and Users must ensure that no person uses any part
                of the Service: (1) to break any law (including participating in money laundering, terrorist financing
                or wash trading) or infringe any person's rights (including Intellectual Property Rights); (2) to
                transmit, publish or communicate material that is defamatory, offensive, abusive, indecent, menacing or
                unwanted; (3) to develop, utilize, or disseminate any software or interact with the Service in any
                manner, that could harm, or impair it; (4) use any robot, spider, crawler, scraper, script, browser
                extension, offline reader or other automated means or interface not authorized by us to access the
                Service, extract data or otherwise interfere with or modify the rendering of Service or functionality or
                (5) in any way that damages, interferes with or interrupts the supply of the Service.
              </li>
            </ul>
          </p>

          <div className={styles.subTitle}>6. EXCLUSIONS TO LIABILITY</div>

          <p>
            <i>6.1</i> Despite anything to the contrary, to the maximum extent permitted by law, we, our Personnel, our
            contractors and suppliers, will not be liable for, and Users waive and release us, our Personnel, the API
            Provider, API Provider’s suppliers and contractors and API Provider’s Personnel, from and against, any
            Liability caused or contributed to by, arising from or connected with:
            <ul>
              <li>Users or Users Personnel's acts or omissions;</li>
              <li>
                loss of, or damage to, any property or any injury or loss to any person, loss of profits or loss of
                expectation, loss of goodwill, loss of business, loss of data or any other indirect or consequential
                loss or damage howsoever arising;
              </li>
              <li>
                any use or application of the Service by a person or entity other than the User, or other than as
                reasonably contemplated by these Terms;
              </li>
              <li>
                any use of the Service, including for any loss of an NFT Asset, digital assets or tokens, and including
                in respect of any loss of profit, loss of revenue, loss of opportunities or loss of use, whether due to
                security breach or cyber-attack, or electronic or technological failure, registration errors or for any
                other reason;
              </li>
              <li>
                the Service being unavailable, or any delay in us providing the Service to Users, for whatever reason;
                and/or
              </li>
              <li>any event outside of our reasonable control (including a Force Majeure Event).</li>
            </ul>
          </p>

          <p>
            <i>6.2</i> This clause 6 will survive the termination or expiry of these Terms.
          </p>

          <div className={styles.subTitle}>7. LIMITATIONS ON LIABILITY</div>

          <p>
            <i>7.1</i> Despite anything to the contrary, to the maximum extent permitted by law:
            <ul>
              <li>we will not be liable for Consequential Loss;</li>
              <li>
                each Party's liability for any Liability under these Terms will be reduced proportionately to the extent
                the relevant Liability was caused or contributed to by the acts or omissions of the other Party (or any
                of that Party's Personnel), including a failure to mitigate; and
              </li>
            </ul>
          </p>

          <p>
            <i>7.2</i> This clause 7 will survive the termination or expiry of these Terms.
          </p>

          <div className={styles.subTitle}>8. TERMS OF USE; PROHIBITED ACTIVITIES</div>

          <p>
            <i>8.1</i> YOU AGREE THAT YOU ARE RESPONSIBLE FOR YOUR OWN CONDUCT WHILE ACCESSING OR USING THE SERVICE, AND
            FOR ANY CONSEQUENCES THEREOF. YOU AGREE TO USE THE SERVICE AT YOUR OWN RISK AND ONLY FOR PURPOSES THAT ARE
            LEGAL PROPER AND IN ACCORDANCE WITH THESE TERMS AND ANY APPLICABLE LAWS OR REGULATIONS.
          </p>

          <p>
            <i>8.2</i> We hereby grant you a limited, non-exclusive, non-transferable, revocable license to access and
            use the Service offered thereunder in accordance with these Terms. Our grant of such license is subject to
            the following conditions. When using the Service and the Services offered thereunder, you acknowledge,
            agree, warrant and undertake not to (and shall not, knowingly or otherwise, authorize, allow or assist any
            other party to):
            <ul>
              <li>
                use the Service to perform any unlawful activities that violate such applicable laws or regulations
                (including but not limited to money laundering, terrorism financing and/or fraudulent activities) or
                otherwise engage in any immoral activities;
              </li>
              <li>
                use the Service if you are not 18 years old (or the minimum age required in your jurisdiction of
                residence to use the Service), or are temporarily or indefinitely suspended from using the Service, or
                are a person with whom transactions are prohibited under economic or trade sanctions or embargoes;
              </li>
              <li>
                use the Service for any commercial use (for example, any business (whether paid or unpaid), advertising,
                or marketing purposes);
              </li>
              <li>
                use the Service to engage in any activity which operates to defraud Alteon LaunchPad or to provide any
                false, inaccurate, or misleading information;
              </li>
              <li>
                use the Service without fully complying with all of our requests for additional information or
                documentation from you, which additional information or documentation shall be complete, truthful,
                accurate and reliable;
              </li>
              <li>
                use the Service to conduct electronic spamming or otherwise distribute any unsolicited or unauthorized
                advertising, promotional or marketing material, junk or chain messages;
              </li>
              <li>
                use the Service to upload content on the Service that contains or is infected with viruses, malicious
                codes, Trojan horses, is immoral or illegal or contains any other harmful or deleterious programmes or
                items of a destructive or deceptive nature;
              </li>
              <li>
                transfer your Account and the related login credentials to any other party without our prior written
                consent;
              </li>
              <li>modify or adapt, in whole or any part of, the Service into any other programme or application;</li>
              <li>
                disassemble, decompile, reverse-engineer or otherwise attempt to derive the source code, object code,
                underlying concepts, ideas and algorithms of the Service or any components thereof;
              </li>
              <li>
                use any robot, spider, crawler, scraper, script, deep link or other similar automated data gathering or
                extraction tool, program, algorithm or methodology to access, copy, modify or monitor the Service;
              </li>
              <li>
                use the Service in any manner that would cause you to infringe any content, copyright, trademark,
                patent, publicity, moral right, database or other intellectual property rights (collectively, the{' '}
                <i>“Intellectual Property Rights”</i>) that belong to or are licensed to us, our affiliates, or any
                third-party, and you undertake not to take or attempt to take any action, or claim ownership of any
                property, that infringes or would infringe upon our intellectual property interests;
              </li>
              <li>
                use the Service in a way that could damage, disable, impair or compromise the Service or interfere with
                other users or affect the reputation of Us;
              </li>
              <li>
                take any action that imposes an unreasonable or disproportionately large burden or load on Our, or
                Immutable X infrastructure (including, but without limitation to, our servers, networks, data centers
                and related or like equipment), nor will you detrimentally interfere with, intercept or expropriate any
                system, data or information belonging to other users of the Service;
              </li>
              <li>
                engage in any other activities deemed inappropriate by us or which are in contravention of these Terms,
                nor will you do anything on the Service that may be deemed objectionable to, or could harm the
                reputation or Intellectual Property Rights of, us or our licensors;
              </li>
              <li>
                provide false, inaccurate, incomplete or misleading information to us or any of our affiliates or
                third-party services providers;
              </li>
              <li>impersonate another person (via the use of an email address or otherwise)</li>
              <li>
                use, employ, operate or create a computer program to simulate the human behavior of a user (“Bots”), and
                or use, employ or operate such Bots or other similar forms of automation to engage in any activity or
                transaction on the Service;
              </li>
              <li>
                breach our privacy or confidentiality or the privacy or confidentiality of any user of the Service;
              </li>
              <li>create nor register a security interest or encumbrance in or over any NFT;</li>
              <li>
                assist another person, entity, contractual arrangement, algorithm or device to do anything prohibited in
                paragraphs (a) to (y) above (each, a <i>“Prohibited Activity”</i> and collectively, the{' '}
                <i>“Prohibited Activities”</i>).
              </li>
            </ul>
          </p>

          <p>
            <i>8.3 Legal compliance</i> <br />
            You are responsible for complying with all trade regulations and both foreign and domestic laws as it
            relates to your access to, and use of, the Service. You will not use the Service if (a) you are located in a
            country that is subject to Sanctions or that has been designated by any of the Sanctions authorities as a
            “terrorist supporting” country or (b) you have been listed on any Sanctions authority’s list of prohibited
            or restricted parties.
          </p>

          <p>
            <i>8.4 Effect of your breach</i> <br />
            If you engage in any of the Prohibited Activities, we may, at our sole and absolute discretion, without
            notice or liability to you, and without limited any of our other rights or remedies at law or in equity,
            immediately suspend or terminate your user account and/or delete your NFTs’ images and descriptions from the
            Service. If we delete your NFTs’ images and descriptions from the Service, such deletion will not affect
            your ownership rights in any NFTs that you already Own, but you will not receive a refund of any amounts you
            paid for such NFTs.
            <br />
            NOTWITHSTANDING THE FOREGOING, HOWEVER, IF WE REASONABLY BELIEVE THAT YOU ARE ENGAGED IN ANY OF THE
            PROHIBITED ACTIVITIES SET FORTH IN PARAGRAPHS (q) TO (u) OF SECTION 12.2, IN ADDITION TO OUR RIGHT TO
            IMMEDIATELY SUSPEND OR TERMINATE YOUR USER ACCOUNT AND/OR DELETE YOUR NFTs IMAGES AND DESCRIPTIONS FROM THE
            SERVICE, WE ALSO RESERVE THE RIGHT, AT OUR SOLE AND ABSOLUTE DISCRETION AND WITHOUT NOTICE OR LIABILITY TO
            YOU, TO TAKE ANY OR ALL OF THE FOLLOWING ACTIONS: (A) TO DEEM ANY TRANSACTION THAT TOOK PLACE VIA OR AS THE
            RESULT OF SUCH ACTIVITIES TO BE VOID AB INITIO; AND/OR (B) TO IMMEDIATELY CONFISCATE ANY NFTs THAT WERE
            PURCHASED OR ACQUIRED AS THE RESULT OF SUCH ACTIVITIES WITHOUT ANY LIABILITY TO YOU TO REIMBURSE OR REFUND
            YOU FOR SUCH CONFISCATED NFTs.
          </p>

          <div className={styles.subTitle}>9. TERMINATION</div>

          <p>
            <i>9.1</i> If at any time Users do not agree to these Terms, they should immediately cease using the
            Service.
          </p>

          <p>
            <i>9.2</i> We may suspend User access to the Service or terminate these Terms immediately on written notice
            to a User, including by suspending a User’s ability to publish an NFT, if:
            <ul>
              <li>at any time, we discontinue the Service, in whole or in part;</li>
              <li>
                Users (or any of their Personnel) breach any provision of these Terms or we reasonably suspect that
                Users (or any of their Personnel) have breached any provision of these Terms;
              </li>
              <li>
                there is any reason outside Alteon LaunchPad’s control which has the effect of compromising Alteon
                LaunchPad’s ability to provide the Service, including a Force Majeure Event
              </li>
            </ul>
          </p>

          <p>
            <i>9.3</i> Termination of these Terms will not affect any rights or liabilities that a Party has accrued
            under it.
          </p>

          <div className={styles.subTitle}>10. DATA COLLECTION NOTICE</div>

          <p>
            <i>10.1</i> The Service does not collect personal information about Users. Users consent to the Service
            collecting, holding, using any information in accordance with the Alteon LaunchPad Platform Privacy Policy.
          </p>

          <p>
            <i>10.2</i> Users manage their own Content, wallet and NFTs published on a blockchain.
          </p>

          <div className={styles.subTitle}>11. RISKS</div>

          <p>
            <i>11.1</i> Value and Volatility You understand and agree that your access to, and use of, the NFTs is
            subject to certain risks including without limitation:
            <ul>
              <li>
                The price and liquidity of collectible blockchain assets, including the NFTs, are extremely volatile and
                subjective and may be subject to fluctuations;
              </li>
              <li>(b) Collectible blockchain assets, including the NFTs, have no inherent or intrinsic value;</li>
              <li>
                Fluctuations in the price of other digital assets could materially and adversely affect the value of
                your NFTs, which may also be subject to significant price volatility.;
              </li>
              <li>NFTs are not legal tender and are not backed by any government;</li>
              <li>
                Transactions involving NFTs may be irreversible, and losses due to fraudulent or accidental transactions
                may not be recoverable, including accidental transactions whereby you provide wrong wallet addresses;
              </li>
              <li>
                The value of collectibles, including the NFTs, is inherently subjective, and factors occurring outside
                the Service ecosystem may materially impact the value and desirability of any particular NFT;
              </li>
              <li>
                The value of NFTs may be derived from the continued willingness of market participants to exchange fiat
                currency or digital assets for NFTs, and therefore the value of NFTs is subject to the potential for
                permanent or total loss of value should the market for NFTs disappear; and
              </li>
              <li>
                NFTs are subject to the risk of fraud, counterfeiting, cyberattacks and other technological difficulties
                which may prevent access to or use of your NFTs.
              </li>
            </ul>
          </p>

          <p>
            <i>11.2</i> Cryptocurrency Risks <br />
            There are risks associated with using a cryptocurrency, including, but not limited to, the risk of hardware,
            software and internet connections, the risk of malicious software introduction, and the risk that third
            parties may obtain unauthorized access to information stored with your electronic wallet. You acknowledge
            and accept that Alteon LaunchPad will not be responsible for any communication failures, disruptions,
            errors, distortions or delays you may experience when using the Service, the NFTs or the Ethereum blockchain
            network.
          </p>

          <p>
            <i>11.3</i> Regulatory Uncertainty <br />
            The regulatory regime governing blockchain technologies, cryptocurrencies and tokens is uncertain, and new
            regulations or policies may materially adversely affect the development of the Service, and by extension,
            the use, transfer, value and potential utility of your NFTs.
          </p>

          <p>
            <i>11.4</i> Your Own Risk <br />
            You understand and agree that you are solely responsible for determining the nature, potential value,
            suitability and appropriateness of these risks for yourself. Alteon LaunchPad does not give any advice or
            recommendations regarding the NFTs. You understand and agree that you access and use the Service at your own
            risk. You understand and agree that Alteon LaunchPad will not be responsible for any communication failures,
            disruptions, errors, or distortions you may experience while creating the NFTs or using the Service.
          </p>

          <div className={styles.subTitle}>12. DISCLAIMERS</div>

          <p>
            EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN WRITING BY US, YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR
            ACCESS TO AND USE OF THE SERVICE IS AT YOUR SOLE RISK AND THAT THE SERVICE AND THE NFTs LISTED ON THE
            SERVICE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
            EITHER EXPRESS OR IMPLIED.
          </p>
          <p>
            YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET, AND
            YOU AGREE THAT WE HAVE NO LIABILITY OR RESPONSIBILITY FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO OUR
            GROSS NEGLIGENCE.
          </p>
          <p>
            WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS OF ANY KIND THAT YOU INCUR AS A RESULT OF YOUR USE
            OF THE SERVICE, THE ETHEREUM BLOCKCHAIN NETWORK, THE IMMUTABLE X PROTOCOL, OR YOUR ELECTRONIC WALLET,
            INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES OR CLAIMS ARISING FROM: (I) USER ERROR, SUCH AS FORGOTTEN
            PASSWORDS OR INCORRECTLY CONSTRUED SMART CONTRACTS OR OTHER TRANSACTIONS; (II) SERVER FAILURE OR DATA LOSS;
            (III) CORRUPTED WALLET FILES; OR (IV) UNAUTHORIZED ACCESS OR ACTIVITIES BY THIRD PARTIES, INCLUDING, BUT NOT
            LIMITED TO, THE USE OF VIRUSES, PHISHING, BRUTE-FORCING OR OTHER MEANS OF ATTACK AGAINST THE SERVICE, THE
            ETHEREUM BLOCKCHAIN NETWORK, THE IMMUTABLE X PROTOCOL ,OR ANY ELECTRONIC WALLET. WE WILL NOT BE RESPONSIBLE
            OR LIABLE TO YOU FOR ANY LOSS OF ANY KIND FROM ANY ACTION THAT RESULTS FROM YOU PROVIDING AN INCORRECT
            WALLET ADDRESS.
          </p>
          <p>
            TO THE FULLEST EXTENT PROVIDED BY LAW, WE HEREBY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
            IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, FITNESS
            FOR A PARTICULAR PURPOSE, TITLE, CORRECTNESS, ACCURACY, RELIABILITY AND NON-INFRINGEMENT AS TO THE SERVICE.
            WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE, OUR PARENT, SUBSIDIARIES, AFFILIATES, AND LICENSORS DO
            NOT REPRESENT OR WARRANT TO YOU THAT: (I) YOUR ACCESS TO OR USE OF THE SERVICE WILL MEET YOUR REQUIREMENTS;
            (II) YOUR ACCESS TO OR USE OF THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR FREE FROM ERROR; (III)
            USAGE DATA PROVIDED THROUGH THE SERVICE WILL BE ACCURATE; (IV) THE SERVICE OR ANY CONTENT, SERVICES, OR
            FEATURES MADE AVAILABLE ON OR THROUGH THE SERVICE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR (V)
            THAT ANY DATA THAT YOU DISCLOSE WHEN YOU USE THE APP WILL BE SECURE. THE FOREGOING DOES NOT AFFECT ANY
            WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
          </p>
          <p>
            NFTs ARE INTANGIBLE DIGITAL ASSETS THAT EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD MAINTAINED IN THE
            BLOCKCHAIN NETWORK. ALL SMART CONTRACTS, INCLUDING ANY TRANSFER OF TITLE THAT MIGHT OCCUR IN ANY UNIQUE NFT,
            ARE CONDUCTED AND OCCUR ON THE DECENTRALIZED LEDGER WITHIN THE BLOCKCHAIN NETWORK. ALTEON LAUNCHPAD HAS NO
            CONTROL OVER AND MAKES NO GUARANTEES OR PROMISES WITH RESPECT TO SMART CONTRACTS.
          </p>
          <p>
            ALTEON LAUNCHPAD IS NOT RESPONSIBLE FOR LOSSES DUE TO BLOCKCHAINS OR ANY OTHER FEATURES OF THE BLOCKCHAIN
            NETWORK, OR ANY ELECTRONIC WALLET, INCLUDING BUT NOT LIMITED TO LATE REPORTING BY DEVELOPERS OR
            REPRESENTATIVES (OR NO REPORTING AT ALL) OF ANY ISSUES WITH THE BLOCKCHAIN SUPPORTING THE ETHEREUM
            BLOCKCHAIN NETWORK OR THE IMMUTABLE X PROTOCOL, INCLUDING FORKS, TECHNICAL NODE ISSUES, OR ANY OTHER ISSUES
            RESULTING IN FUND LOSSES.
          </p>

          <div className={styles.subTitle}>13. MISCELLANEOUS</div>

          <p>
            <i>13.1</i> Assignment: Alteon LaunchPad may assign any of Alteon LaunchPad rights or obligations under
            these Terms without your consent and without prior notice to you. You may not assign or transfer any of your
            rights or obligations under these Terms, in whole or in part, by operation of law or otherwise, without
            Alteon LaunchPad’s prior written consent, which may be withheld at our sole discretion. Any actual or
            attempted assignment hereof by User contrary to the terms of these Terms shall be null and void. Alteon
            LaunchPad may, at its discretion, assign some or all of its rights or obligations to a third party, without
            consent or approval of User. In the event that Alteon LaunchPad is acquired by, sold to, or merged with a
            third party entity, Alteon LaunchPad reserves the right to transfer the Service as part of such merger,
            acquisition, sale, or change of control.
          </p>

          <p>
            <i>13.2</i> Disputes: Neither party may commence court proceedings relating to any dispute arising from, or
            in connection with, this Agreement without first meeting with the other party to seek (in good faith) to
            resolve that dispute (unless that party is seeking urgent interlocutory relief).
          </p>

          <p>
            <i>13.3</i> Force Majeure: Alteon LaunchPad will not be liable for any delay or failure to perform its
            obligations under these Terms if such delay is due to any circumstance beyond a reasonable control
            (including but not limited to epidemics, pandemics, and Government sanctioned restrictions and orders,
            whether known or unknown at the time of entering into these Terms) (“Force Majeure Event”). In particular,
            Alteon LaunchPad shall not incur any liability or penalty for not performing any act or fulfilling any duty
            or obligation hereunder or in connection with the matters contemplated hereby by reason of any occurrence
            that is not within Alteon LaunchPad’s control (including any provision of any present or future law or
            regulation or any act of any governmental authority, any act of God or war or terrorism, any epidemic or
            pandemic, or the unavailability, disruption or malfunction of the Internet, the World Wide Web or any other
            electronic network, the Ethereum network or blockchain or Immutable X Protocol or any aspect thereof, or any
            consensus attack, or hack, or denial-of-service or other attack on the foregoing or any aspect thereof, or
            on the other software, networks and infrastructure that enables us to provide the Service). It is understood
            that Alteon LaunchPad shall use commercially reasonable efforts, consistent with accepted practices in the
            industries in which we operate, as applicable, to resume performance as soon as reasonably practicable under
            the circumstances.
          </p>

          <p>
            <i>13.4</i> Governing law: These Terms governed by the laws of Florida, USA. Each Party irrevocably and
            unconditionally submits to the exclusive jurisdiction of the courts operating in Florida, USA and any courts
            entitled to hear appeals from those courts and waives any right to object to proceedings being brought in
            those courts.
          </p>

          <p>
            <i>13.5</i> Notices: Any notice given under these Terms must be in writing addressed to Alteon LaunchPad.
          </p>

          <p>
            <i>13.6</i> Online execution: These Terms may be executed by means of such a third party online document
            execution service as we nominate subject to such execution being in accordance with the applicable terms and
            conditions of that document execution service.
          </p>

          <p>
            <i>13.7</i> Relationship of Parties: These Terms are not intended to create a partnership, joint venture,
            employment or agency relationship (except to the extent set out in the Payment clause as limited payment
            collection agent) between the Parties.
          </p>

          <p>
            <i>13.8</i> Severance: If a provision of these Terms is held to be void, invalid, illegal or unenforceable,
            that provision is to be read down as narrowly as necessary to allow it to be valid or enforceable, failing
            which, that provision (or that part of that provision) will be severed from these Terms without affecting
            the validity or enforceability of the remainder of that provision or the other provisions in these Terms.
          </p>

          <p>
            <i>13.9</i> Amendments. Alteon LaunchPad may, in our absolute and sole discretion, change, update, amend,
            remove, or discontinue any feature or functionality of the Service, the Services rendered thereunder and the
            Content with respect thereto at any time without prior notice to you. By continuing to access or use the
            Service following any such changes to the Service, the Services or the Content taking effect, you will be
            deemed to have accepted and agreed to such updated Service, Services and Content.
          </p>

          <p>
            <i>13.10</i> Headings: Section headings and section labels are provided for convenience only, and do not
            form a part of these Terms and shall not affect in any way the meaning or interpretation of these Terms.
          </p>
        </div>
      )
      break
    default:
      title = (
        <div className={styles.modalTitle}>
          <Icon type="question" className={styles.question} />
          How to use Alteon LaunchPad
        </div>
      )
      content = (
        <div className={styles.video}>
          <iframe
            title="vimeo-player"
            src="https://player.vimeo.com/video/770786006?h=967f6d552f"
            width="640"
            height="360"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      )
      break
  }

  return (
    <Modal
      title={title}
      modalHeaderClass={styles.modalHeader}
      modalContentClass={styles.modalContent}
      modalContainerClass={styles.modalContainer}
      visible
      transparent
      frosted
      shape="rounded"
      onClose={onClose}
      headerSeparator
      width="auto"
    >
      <>{content}</>
      <div className={styles.footer}>
        <a
          className={cx(styles.link, { [styles.active]: activeView === VIEWS.TERMS_OF_SERVICE })}
          href="#"
          onClick={() => setActiveView(VIEWS.TERMS_OF_SERVICE)}
        >
          Terms and Conditions
        </a>
        <a
          className={cx(styles.link, { [styles.active]: activeView === VIEWS.PRIVACY_POLICY })}
          href="#"
          onClick={() => setActiveView(VIEWS.PRIVACY_POLICY)}
        >
          Privacy Policy
        </a>
      </div>
    </Modal>
  )
}

export default VideoModal
