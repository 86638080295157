import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { get } from 'lodash'
import cx from 'classnames'
import Footer from '../Footer/Footer'
import { Icon } from 'components'
import { MintWidgetViewProps } from 'components/MintWidget/MintWidgetTypes'
import { CONNECT_STATUSES, MINT_WIDGET_VIEWS } from 'mainConstants'
import { mintStore } from 'stores'
import { Web3Utils } from 'utils'
import styles from './Wallet.module.scss'

const TEXTS = {
  WALLET_CONNECTED: {
    title: 'Success!',
    description: `Your wallet is connected.`,
    label: 'Get Started',
  },
  WALLET_NOT_CONNECTED: {
    title: 'Your wallet is not connected',
    description: 'You need to have a wallet connected in order to mint your NFTs',
    label: 'Connect or Create wallet',
  },
}

const CONNECT_REQUIRED = '#connect'

function Wallet({ goTo }: MintWidgetViewProps) {
  const navigate = useNavigate()
  const [walletConnected, setWalletConnected] = useState(false)
  const [operaFirstConnection, setOperaFirstConnection] = useState(true)

  const onWalletConnected = useCallback(
    (status: string) => {
      switch (status) {
        case CONNECT_STATUSES.CONNECTED:
          setWalletConnected(true)
          mintStore.setShowInstallWalletModal(false)
          break
        case CONNECT_STATUSES.NO_WALLET:
          setWalletConnected(false)
          mintStore.setShowInstallWalletModal(true)
          break
        case CONNECT_STATUSES.ERROR:
          setWalletConnected(false)
          mintStore.setShowInstallWalletModal(false)
          break
        default:
          setWalletConnected(false)
          mintStore.setShowInstallWalletModal(false)
      }
    },
    [setWalletConnected]
  )

  const connect = useCallback(
    (force = false) => {
      if (get(window, 'ethereum.isOpera')) {
        if (force || operaFirstConnection) {
          setOperaFirstConnection(false)
          Web3Utils.getCurrentWalletConnected().then(onWalletConnected)
        } else {
          navigate(CONNECT_REQUIRED)
          window.location.reload()
        }
      } else {
        Web3Utils.connectWalletNew().then(onWalletConnected)
      }
    },
    [operaFirstConnection, onWalletConnected]
  )

  useEffect(() => {
    if (window.location.hash === CONNECT_REQUIRED) {
      window.history.replaceState('', document.title, window.location.pathname)
      connect(true)
    }
  }, [])

  const onClick = walletConnected ? () => goTo(MINT_WIDGET_VIEWS.UPLOAD) : () => connect()
  const { title, description, label } = walletConnected ? TEXTS.WALLET_CONNECTED : TEXTS.WALLET_NOT_CONNECTED

  return (
    <div className={styles.root}>
      <div>
        <Icon className={styles.wallet} type="walletLg" />
        <div className={styles.title}>{title}</div>
        <div className={styles.desc}>{description}</div>
      </div>
      <Footer label={label} onClick={onClick} buttonClassName={cx({ [styles.cond]: !walletConnected })} />
    </div>
  )
}

export default observer(Wallet)
