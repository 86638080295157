import React, { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import cx from 'classnames'
import { mintStore } from 'stores'
import { AuthorLink, ErrorModal, Header, Icon, InstallWalletModal, MintWidget, Spinner, VideoModal } from 'components'
import styles from './Mint.module.scss'
import { MINT_WIDGET_VIEWS } from '../../mainConstants'

function Mint() {
  const [widgetView, setWidgetView] = useState(MINT_WIDGET_VIEWS.WALLET)
  const [showVideoModal, setShowVideoModal] = useState(false)
  const {
    background,
    loadContentfulData,
    loading,
    showInstallWalletModal,
    setShowInstallWalletModal,
    showErrorModal,
    setError,
    error,
  } = mintStore
  const wideView = widgetView === MINT_WIDGET_VIEWS.EDIT

  useEffect(() => {
    loadContentfulData()
  }, [])

  const toggleShowVideoModal = useCallback(() => {
    setShowVideoModal(!showVideoModal)
  }, [showVideoModal])

  const toggleShowInstallWalletModal = useCallback(() => {
    setShowInstallWalletModal(!showInstallWalletModal)
  }, [showInstallWalletModal])

  const closeShowErrorModal = useCallback(() => {
    setError(null)
  }, [])

  const backWithGradient = `linear-gradient(21.32deg, rgba(0, 0, 0, 0.75) 52.49%, rgba(0, 0, 0, 0) 100%), url(${background}) 50%`

  return (
    <div className={cx(styles.root, { [styles.wide]: wideView })} style={{ background: backWithGradient }}>
      <Spinner show={loading} />
      <Header />
      <div className={styles.widget}>
        <MintWidget className={styles.childWidget} view={widgetView} setView={setWidgetView} wideView={wideView} />
        <div className={styles.description}>
          <div className={styles.title}>Welcome to Alteon LaunchPad</div>
          <div className={styles.slogan}>Bring your NFTs to life</div>
          <div className={styles.text}>
            Upload, mint, monetize. Alteon LaunchPad lets you generate NFTs from any media file, instantly and securely,
            straight from your Crypto Browser.
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <Icon type="question" className={styles.hint} onClick={toggleShowVideoModal} />
        <AuthorLink />
      </div>
      {showVideoModal && <VideoModal onClose={toggleShowVideoModal} />}
      {showInstallWalletModal && <InstallWalletModal onClose={toggleShowInstallWalletModal} />}
      {showErrorModal && <ErrorModal onClose={closeShowErrorModal} error={error} />}
    </div>
  )
}

export default observer(Mint)
