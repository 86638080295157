import React from 'react'
import styles from './Header.module.scss'

function Header() {
  return (
    <div className={styles.root}>
      <div className={styles.logo}>
        <img src="/images/logo.svg" />
      </div>
    </div>
  )
}

export default Header
