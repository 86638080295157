// import { mintStore } from 'stores'
import { CONNECT_STATUSES } from 'mainConstants'
import { objectType } from 'commonTypes'
import contractJson from 'artifacts/AlteonNFT.json'
import { NFTStorage, File } from 'nft.storage'

const Web3 = require('web3')
const web3 = new Web3(Web3.givenProvider || 'ws://localhost:8545')

/* takes in a file, returns a URI
 * reference: https://dev.to/edge-and-node/uploading-files-to-ipfs-from-a-web-application-50a
 */
export async function uploadToIPFSAndMint(image: File, name: string, description: string, attributes: objectType[]) {
  // TODO: @dmytro swap projectId and projectSecret to Yusuf's Infura keys
  console.log('loading nft storage')
  const nftstorage = new NFTStorage({
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDcyNDhENkM1MDJEOTM2NTljRjg2NDA2RDFFN0E1NzM2ZTcwNmIyNzAiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY3MDU5OTUyNTg2MSwibmFtZSI6IkFsdGVvbiBMYXVuY2hwYWQifQ.fsh96PsC3OZiok4ULS9vERRkvPjvmgNJTyZAOHeA0bU',
  })
  console.log('connected to nft storage')

  // call client.store, passing in the image & metadata
  const CID = await nftstorage.store({
    image,
    name,
    description,
    attributes,
  })

  console.log('CID is... ', CID)
  const nft_uri = CID.url
  console.log('NFT URI is... ', nft_uri)

  /** Goerli Testnet address:
   * 0x4966A2739a65F37DF6c1D740245f65399aA4D222
   * Use it for development and testing
   * (change value of const contractAddress but don't commit it to prod) */

  //// this is where we interact with Ethereum!
  const contractAddress = '0xcE486fC8DAf11dE771e2e9Af019199412a0bC479'
  var contract = await new web3.eth.Contract(contractJson, contractAddress)

  // set up your Ethereum transaction
  let transactionParameters = {
    from: window.ethereum.selectedAddress,
    to: contractAddress,
    data: contract.methods.mint(nft_uri).encodeABI(),
    input: contract.methods.mint(nft_uri).encodeABI(),
  }

  // call Mint using Metamask
  const txHash = await window.ethereum.request({
    method: 'eth_sendTransaction',
    params: [transactionParameters],
  })

  return txHash
}

export function getWalletName() {
  if (window.ethereum) {
    // const metaMask = window.ethereum.providers.find((provider: any) => provider.isMetaMask);
    // const coinbase = window.ethereum.providers.find((provider: any) => provider.isMetaMask);
    console.log(window.ethereum)
    if (window.ethereum.isMetaMask) {
      return 'metamask'
    } else if (window.ethereum.isOpera) {
      return 'opera'
    }
    return 'opera'
  }
}

export async function connectWallet() {
  console.log('window.ethereum', window.ethereum)
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: 'eth_requestAccounts',
      })
      console.log(addressArray)
      return true
    } catch (err: any) {
      return false
    }
  } else {
    return false
  }
}

export async function connectWalletNew() {
  // mintStore.setError(null)
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: 'eth_requestAccounts',
      })

      if (addressArray.length > 0) {
        return CONNECT_STATUSES.CONNECTED
      } else {
        return CONNECT_STATUSES.NOT_CONNECTED
      }
    } catch (error: any) {
      // mintStore.setError(error.message)
      return CONNECT_STATUSES.ERROR
    }
  } else {
    return CONNECT_STATUSES.NO_WALLET
  }
}

export async function getCurrentWalletConnected() {
  // mintStore.setError(null)
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: 'eth_accounts',
      })
      if (addressArray.length > 0) {
        return CONNECT_STATUSES.CONNECTED
      } else {
        return CONNECT_STATUSES.NOT_CONNECTED
      }
    } catch (error: any) {
      // mintStore.setError(error.message)
      return CONNECT_STATUSES.ERROR
    }
  } else {
    return CONNECT_STATUSES.NO_WALLET
  }
}
