import React, { useMemo } from 'react'
import { Modal } from 'alteon-component-library'
import { Button, Icon } from 'components'
import { isOperaBrowser } from 'utils/helpers'
import styles from './InstallWalletModal.module.scss'

const METAMASK_LINK = 'https://metamask.io/download/'

interface InstallWalletModalProps {
  onClose: () => void
}

function InstallWalletModal({ onClose }: InstallWalletModalProps) {
  const isOpera = useMemo(isOperaBrowser, [])

  const title = (
    <>
      <Icon type="wallet" className={styles.icon} />
      {isOpera ? 'Enable Opera Wallet' : 'Install wallet extension'}
    </>
  )

  return (
    <Modal
      title={title}
      modalHeaderClass={styles.modalHeader}
      modalContentClass={styles.modalContent}
      modalContainerClass={styles.modalContainer}
      visible
      transparent
      frosted
      shape="rounded"
      onClose={onClose}
      headerSeparator
      width="auto"
    >
      {isOpera ? (
        <div className={styles.body}>Please enable Opera Wallet and create a wallet to get started.</div>
      ) : (
        <div className={styles.body}>
          Please install
          <a className={styles.link} href={METAMASK_LINK} target="_blank" rel="noreferrer">
            MetaMask
          </a>
          and create a wallet to get started.
        </div>
      )}
      <div className={styles.footer}>
        <Button label="OK" onClick={onClose} type="grey" />
      </div>
    </Modal>
  )
}

export default InstallWalletModal
