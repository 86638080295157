export enum MINT_WIDGET_VIEWS {
  DONE = 'Done',
  EDIT = 'Edit',
  UPLOAD = 'Upload',
  WALLET = 'Wallet',
}

export enum CONNECT_STATUSES {
  CONNECTED = 'Connected',
  NOT_CONNECTED = 'Not connected',
  NO_WALLET = 'No wallet',
  ERROR = 'Error',
}

export const MB_100 = 104857600

export const LINE_HEIGHT = 24
export const MIN_TEXT_HEIGHT = LINE_HEIGHT * 1
export const CONTRACT_TYPE = 'ERC-721'
