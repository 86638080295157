import React, { useCallback } from 'react'
import { observer } from 'mobx-react'
import { TagInput, TextArea, TextInput } from 'alteon-component-library'
import { mintStore } from 'stores'
import Header from '../Header/Header'
import { Button, Icon, Tooltip } from 'components'
import { helpers, Web3Utils } from 'utils'
import { MintWidgetViewProps } from 'components/MintWidget/MintWidgetTypes'
import { CONTRACT_TYPE, LINE_HEIGHT, MIN_TEXT_HEIGHT, MINT_WIDGET_VIEWS } from 'mainConstants'
import styles from './Edit.module.scss'

function Edit({ goTo }: MintWidgetViewProps) {
  const {
    editForm: { name, description, descriptionHeight, properties },
    image: { name: imageName, src, size, file },
    setEditForm,
    setError,
    setLoading,
    setTransactionHash,
  } = mintStore

  const onDescriptionChange = useCallback(
    (e: any) => {
      const height = helpers.calculateContentHeight(e.target, LINE_HEIGHT)
      const newHeight = height < MIN_TEXT_HEIGHT ? MIN_TEXT_HEIGHT : height
      setEditForm('descriptionHeight', `${newHeight}px`)
      setEditForm('description', e.target.value)
    },
    [description]
  )

  const onNameChange = useCallback(
    (e: any) => {
      setEditForm('name', e.target.value)
    },
    [setEditForm]
  )

  const onPropertiesChange = useCallback(
    (newProperties: string[]) => {
      setEditForm('properties', newProperties)
    },
    [setEditForm]
  )

  const mint = useCallback(() => {
    setLoading(true)
    setError(null)
    const attributes = properties.map((value) => ({
      trait_type: 'Property',
      value,
    }))

    Web3Utils.uploadToIPFSAndMint(file, name, description, attributes)
      .then((hash) => {
        setTransactionHash(hash)
        goTo(MINT_WIDGET_VIEWS.DONE)
        setLoading(false)
      })
      .catch((err) => {
        setTransactionHash(null)
        setError(err.message)
        console.log('Minting Error', err)
        setLoading(false)
      })
  }, [file, name, description, properties])

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        <Header icon="hexagon" text="Smart Contract" />
        <div className={styles.form}>
          <div className={styles.imageContainer}>
            <img className={styles.image} src={src} alt="Your Image" />
            <div className={styles.imageInfo}>
              <div>
                <div className={styles.imageName}>{imageName}</div>
                <div className={styles.imageSize}>{size}</div>
              </div>
              <a className={styles.imageLink} href={src} target="_blank">
                Preview
              </a>
            </div>
          </div>
          <div className={styles.formItem}>
            <TextInput label="Name" onChange={onNameChange} value={name} />
          </div>
          <div className={styles.formItem}>
            <TextArea
              height={descriptionHeight}
              label="Description"
              onChange={onDescriptionChange}
              textAreaClassName={styles.area}
              value={description}
            />
            <div className={styles.line} />
          </div>
          <div className={styles.formItem}>
            <div className={styles.label}>Properties</div>
            <TagInput
              addLabel="Add"
              maxTagAllowed={5}
              tagType="tag"
              tags={properties}
              updateTags={onPropertiesChange}
              saveOnLeave
            />
            <div className={styles.line} />
          </div>
          <div className={styles.formItem}>
            <div className={styles.label}>Blockchain*</div>
            <div className={styles.block}>
              <div className={styles.ethereum}>
                <div className={styles.ethereumLogo} />
              </div>
              Ethereum
            </div>
            <div className={styles.line} />
          </div>
          <div className={styles.formItem}>Contract type: {CONTRACT_TYPE}</div>
        </div>
      </div>
      <div className={styles.footer}>
        <div>
          <Tooltip
            anchorId="info"
            place="right"
            content="You won't be able to set a price and list your NFT as this is not a marketplace.
          You will have to connect your NFT with a marketplace to do so."
            className={styles.tooltip}
          />
          <Icon type="info" id="info" />
        </div>
        <div>
          <Button label="Back" onClick={() => goTo(MINT_WIDGET_VIEWS.UPLOAD)} type="grey" />
          <Button label="Confirm" onClick={mint} className={styles.confirm} />
        </div>
      </div>
    </div>
  )
}

export default observer(Edit)
