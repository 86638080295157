import React, { useCallback, useEffect, useRef, useState } from 'react'
import { find } from 'lodash'
import cx from 'classnames'
import { useOutsideClick } from 'utils'
import { Icon } from 'components'
import styles from './Select.module.scss'

type SelectOption = {
  icon?: string
  label: string
  value: string
}

interface SelectProps {
  label?: string
  onChange: (optionValue: SelectOption) => void
  options: SelectOption[]
  selected: SelectOption
}

function Select({ label, onChange, options, selected }: SelectProps) {
  const [activeOption, setActiveOption] = useState<string | null>(null)
  const [showMenu, setShowMenu] = useState(false)
  const ref = useRef<HTMLDivElement | null>(null)

  const onValueClick = useCallback(() => {
    setShowMenu(!showMenu)
  }, [showMenu])

  const onMenuHover = useCallback(
    (event: React.MouseEvent) => {
      if (!(event.target instanceof HTMLElement)) {
        return
      }
      const { value: hoveredOption } = event.target.dataset
      if (hoveredOption && hoveredOption !== activeOption) {
        setActiveOption(hoveredOption)
      }
    },
    [activeOption]
  )

  const setOption = useCallback(
    (value: any) => {
      const option = find(options, ['value', value])
      if (option) {
        onChange(option)
        setShowMenu(false)
      }
    },
    [onChange]
  )

  const onMenuClick = useCallback(
    (event: React.MouseEvent) => {
      if (!(event.target instanceof HTMLElement)) {
        return
      }
      const { value } = event.target.dataset
      setOption(value)
    },
    [setOption]
  )

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      setOption(event.key)
    },
    [setOption]
  )

  useOutsideClick(
    ref,
    () => {
      if (showMenu) {
        setShowMenu(false)
      }
    },
    showMenu
  )

  return (
    <div className={styles.root} ref={ref} onKeyDown={handleKeyDown} tabIndex={0}>
      <div className={styles.label}>{label}</div>
      <div className={styles.value} onMouseDown={onValueClick}>
        <div className={styles.name}>
          {selected.icon && <Icon type={selected.icon} className={styles.icon} />} {selected.label}
        </div>
        <Icon type="caretDownLight" className={cx(styles.caret, { [styles.open]: showMenu })} />
      </div>
      <ul
        className={cx(styles.menu, { [styles.visible]: showMenu })}
        onMouseMove={onMenuHover}
        onMouseDown={onMenuClick}
      >
        {options.map(({ icon, label, value }) => (
          <li key={label} data-value={value} className={cx({ [styles.active]: value === activeOption })}>
            {icon && <Icon type={icon} className={styles.icon} />} {label}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Select
