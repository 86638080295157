import React from 'react'
import { Modal } from 'alteon-component-library'
import { Button, Icon } from 'components'
import styles from './ErrorModal.module.scss'

interface InstallWalletModalProps {
  error: null | string
  onClose: () => void
}

function ErrorModal({ error, onClose }: InstallWalletModalProps) {
  const titleComponent = (
    <>
      <Icon type="info" className={styles.icon} />
      Error
    </>
  )

  return (
    <Modal
      title={titleComponent}
      modalHeaderClass={styles.modalHeader}
      modalContentClass={styles.modalContent}
      modalContainerClass={styles.modalContainer}
      visible
      transparent
      frosted
      shape="rounded"
      onClose={onClose}
      headerSeparator
      width="auto"
    >
      <div className={styles.body}>{error}</div>
      <div className={styles.footer}>
        <Button label="OK" onClick={onClose} type="grey" />
      </div>
    </Modal>
  )
}

export default ErrorModal
